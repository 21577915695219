<template>
  <div class="highlight-form">
    <div class="highlight-form__form-group">
      <label
        for="field-select"
        class="highlight-form__form-label"
      >
        Column
      </label>
      <HighlightFormSelect
        id="field-select"
        :model-value="highlight.type"
        :options="typeOptions"
        placeholder="Select column"
        @update:model-value="setType"
      />
    </div>
    <div class="highlight-form__form-group">
      <label
        for="condition-select"
        class="highlight-form__form-label"
      >
        Condition
      </label>
      <HighlightFormConditionSelect
        id="condition-select"
        :model-value="conditionSelectValue"
        :options="conditionTypeOptions"
        placeholder="Select condition"
        @update:model-value="updateConditionSelectValue"
      />
    </div>
    <div class="highlight-form__form-group">
      <label
        for="color-select"
        class="highlight-form__form-label"
      >
        Color
      </label>
      <ColorPicker
        id="color-select"
        :model-value="highlight.color"
        @update:model-value="setColor"
      />
    </div>
    <div class="highlight-form__form-group">
      <label class="highlight-form__form-label">
        Text color
      </label>
      <HighlightFormRadioList
        :model-value="highlight.textColor"
        :options="textColorOptions"
        @update:model-value="setTextColor"
      />
    </div>
    <div class="highlight-form__form-group">
      <label class="highlight-form__form-label">
        Apply to
      </label>
      <HighlightFormRadioList
        :model-value="highlight.highlightType"
        :options="highlightTypeOptions"
        @update:model-value="setHighlightType"
      />
    </div>
    <HighlightFormPreview
      :type="highlight.highlightType"
      :background-color="highlight.color"
      :color="highlight.textColor"
    />
    <div class="highlight-form__footer">
      <Button
        variant="tertiary"
        @click="discardChanges"
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        :disabled="hasEmptyRequiredValues"
        @click="saveChanges"
      >
        Save
      </Button>
    </div>
  </div>
</template>

<script>
import { computed, watch } from 'vue';
import {
  typeOptions,
  textColorOptions,
  highlightTypeOptions,
  conditionTypeOptions,
  parseBetHighlightParameter,
  composeBetHighlightParameter,
  getBetHighlightDefaultParameter,
  isBetHighlightParameterValid,
} from '@/services/helpers/highlights';
import Button from '@/components/common/Button';
import ColorPicker from '@/components/common/ColorPicker';
import HighlightFormSelect from './HighlightFormSelect';
import HighlightFormConditionSelect from './HighlightFormConditionSelect';
import HighlightFormRadioList from './HighlightFormRadioList';
import HighlightFormPreview from './HighlightFormPreview';

export default {
  components: {
    Button,
    ColorPicker,
    HighlightFormSelect,
    HighlightFormConditionSelect,
    HighlightFormRadioList,
    HighlightFormPreview,
  },
  props: {
    highlight: {
      type: Object,
      required: true,
    },
    oddsFormat: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:highlight': {
      type: Object,
    },
    submit: {},
    cancel: {},
  },
  setup(props, { emit }) {
    const selectedType = computed(() => typeOptions[props.highlight.type]);
    const selectedTextColor = computed(() => textColorOptions[props.highlight.textColor]);
    const selectedHighlightType = computed(() => highlightTypeOptions[props.highlight.highlightType]);
    const previewCellStyle = computed(() => ({
      'background-color': props.highlight.color,
      color: selectedTextColor.value?.value,
    }));
    const hasEmptyRequiredValues = computed(() => {
      const requiredFields = ['type', 'conditionType', 'color', 'textColor', 'highlightType'];
      // eslint-disable-next-line no-restricted-syntax
      for (const field of requiredFields) {
        if (!props.highlight[field]) return true;
      }
      if (!isBetHighlightParameterValid(props.highlight.type, props.highlight.parameter, props.oddsFormat)) return true;
      return false;
    });
    const conditionSelectValue = computed(() => ({
      condition: props.highlight.conditionType,
      parameter: composeBetHighlightParameter(props.highlight.type, props.highlight.parameter, props.oddsFormat),
    }));

    const updateHighlight = (updatedFields) => {
      emit('update:highlight', {
        ...props.highlight,
        ...updatedFields,
      });
    };
    const setType = (newType) => {
      updateHighlight({ type: newType });
    };
    const setColor = (newColor) => {
      updateHighlight({ color: newColor });
    };
    const setTextColor = (newTextColor) => {
      updateHighlight({ textColor: newTextColor });
    };
    const setHighlightType = (newHighlightType) => {
      updateHighlight({ highlightType: newHighlightType });
    };
    const updateConditionSelectValue = (newValue) => {
      updateHighlight({
        conditionType: newValue.condition,
        parameter: parseBetHighlightParameter(props.highlight.type, newValue.parameter, props.oddsFormat),
      });
    };

    const saveChanges = () => {
      emit('submit');
    };
    const discardChanges = () => {
      emit('cancel');
    };

    watch(() => props.highlight.type, (newHighlightType) => {
      updateHighlight({
        condition: props.highlight.conditionType,
        parameter: getBetHighlightDefaultParameter(newHighlightType, props.oddsFormat),
      });
    });

    return {
      typeOptions,
      selectedType,
      textColorOptions,
      selectedTextColor,
      highlightTypeOptions,
      selectedHighlightType,
      conditionTypeOptions,
      previewCellStyle,
      hasEmptyRequiredValues,
      conditionSelectValue,
      setType,
      setColor,
      setTextColor,
      setHighlightType,
      updateConditionSelectValue,
      saveChanges,
      discardChanges,
    };
  },
};
</script>

<style lang="scss">
.highlight-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  &__form {
    &-group {
      display: flex;
      flex-direction: column;
      gap: 2px;
      padding: 0 16px;
    }

    &-label {
      font-family: 'Rubik', sans-serif;
      font-size: 10px;
      font-weight: 600;
      line-height: 11.85px;
      color: #6D6D6D;
      text-transform: uppercase;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    padding: 16px;
    height: 64px;
    border-top: 1px solid #F0F0F0;

    .button {
      width: 96px;
      text-align: center;
      justify-content: center;

      &[disabled] {
        cursor: not-allowed;
      }
    }
  }
}
</style>
