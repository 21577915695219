<template>
  <div class="highlight-form-radio-list">
    <div
      v-for="option in options"
      :key="option[idKey]"
      class="highlight-form-radio-list__option"
      @click="updateModelValue(option[idKey])"
    >
      <RadioInput
        :checked="modelValue === option[idKey]"
      />
      {{ option[labelKey] }}
    </div>
  </div>
</template>

<script>
import RadioInput from '@/components/common/RadioInput';

export default {
  components: {
    RadioInput,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    idKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'label',
    },
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
  },
  setup(props, { emit }) {
    const updateModelValue = (newModelValue) => {
      emit('update:modelValue', newModelValue);
    };

    return {
      updateModelValue,
    };
  },
};
</script>

<style lang="scss">
.highlight-form-radio-list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__option {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 16px;
    cursor: pointer;
    user-select: none;

    &:first-of-type {
      margin-top: 6px;
    }
  }
}
</style>
