<template>
  <div class="filters-listing">
    <div class="filters-listing__form-group">
      <div class="filters-listing__form-label">
        {{ filtersLabel }}
      </div>
      <ul class="filters-listing__list">
        <li
          v-if="!filters.length"
          class="filters-listing__message"
        >
          There aren't any filters.
        </li>
        <li
          v-for="filter in filters"
          :key="JSON.stringify(filter)"
          class="filters-listing-item"
        >
          <div
            v-if="!filter.hideLabel"
            class="filters-listing-item-header"
          >
            {{ filter.label }}
          </div>
          <component
            v-if="getComponent(filter)"
            :is="getComponent(filter)"
            :filter="filter"
            :error="getError(filter)"
            @update:filter="setFilter"
            @remove-filter="removeFilter"
          />
          <div
            v-if="getError(filter)"
            class="filters-listing-item-error"
          >
            <Icon
              class="filters-listing-item-error-icon"
              name="exclamation"
            />
            <span class="filters-listing-item-error-message">
              {{ getError(filter) }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { dataTypes, getFilterError } from '@/services/helpers/bet-ticker-filters';
import Icon from '@/components/common/Icon';
import ConditionSelect from './inputs/ConditionSelect';
import UuidTextInput from './inputs/UuidTextInput';
import SportAndCompetitionSelect from './inputs/SportAndCompetitionSelect';

export default {
  components: {
    Icon,
    ConditionSelect,
    UuidTextInput,
    SportAndCompetitionSelect,
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    filtersLabel: {
      type: String,
      default: 'Filters',
    },
  },
  setup() {
    const store = useStore();

    const getComponent = (filter) => {
      switch (filter.type) {
      case dataTypes.UUID:
        return 'UuidTextInput';
      case dataTypes.SPORT_AND_COMPETITION:
        return 'SportAndCompetitionSelect';
      case dataTypes.NUMBER:
      case dataTypes.PERCENTAGE:
        return 'ConditionSelect';
      default:
        return '';
      }
    };
    const getError = (filter) => getFilterError(filter);
    const setFilter = (filter) => {
      store.dispatch('betTicker/updateFilters', filter);
    };
    const removeFilter = (filter) => {
      store.dispatch('betTicker/toggleFilters', filter);
    };

    return {
      getComponent,
      getError,
      setFilter,
      removeFilter,
    };
  },
};
</script>

<style lang="scss">
.filters-listing {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;

  &__form {
    &-group {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 0 16px;
    }

    &-label {
      display: flex;
      align-items: center;
      gap: 4px;
      font-family: 'Rubik', sans-serif;
      font-size: 10px;
      font-weight: 600;
      line-height: 11.85px;
      color: #175FFF;
      text-transform: uppercase;

      .icon {
        display: inline-block;
        fill: #6D6D6D;
        width: 12px;
        height: 12px;
        max-width: 12px;
        max-height: 12px;
      }

      .tooltip {
        text-transform: none;
        font-weight: 400;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    padding: 16px;
    height: 64px;
    border-top: 1px solid #F0F0F0;

    .button {
      min-width: 130px;
      text-align: center;
      justify-content: center;
      margin-left: auto;

      .icon {
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }

  .filters-listing-item {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    flex-direction: column;

    .filters-listing-item-header {
      width: 100%;
      color: #6D6D6D;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 600;
    }

    &-error {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 10px;
      color: $error500;
      margin-top: 4px;

      &-icon {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        overflow: hidden;

        svg {
          background-color: $error500;
          fill: #fff;
          width: 100%;
          height: 100%;
        }
      }

      &-message {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
