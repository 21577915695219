<template>
  <div
    :class="['bet-ticker-sidebar', activeSidebarOption && 'active']"
  >
    <div class="sidebar-header">
      <span>{{ title }}</span>
      <Icon
        name="close"
        @click="closeSidebar"
      />
    </div>
    <div class="sidebar-content">
      <ColumnsSidebar v-if="columnsSidebarActive" />
      <FiltersSidebar v-if="filtersSidebarActive" />
      <HighlightsSidebar v-if="highlightsSidebarActive" />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, watch } from 'vue';
import Icon from '@/components/common/Icon';
import { SIDEBAR_OPTIONS } from '../betTicker-helper';
import ColumnsSidebar from './ColumnsSidebar';
import FiltersSidebar from './filters/FiltersSidebar';
import HighlightsSidebar from './HighlightsSidebar';

export default {
  components: {
    Icon,
    ColumnsSidebar,
    FiltersSidebar,
    HighlightsSidebar,
  },
  setup() {
    const store = useStore();
    const selectedBetTicker = computed(() => store.getters['betTicker/selectedBetTicker']);
    const activeSidebarOption = computed(() => store.getters['betTicker/activeSidebarOption']);
    const columnsSidebarActive = computed(() => activeSidebarOption.value === SIDEBAR_OPTIONS.COLUMNS);
    const filtersSidebarActive = computed(() => activeSidebarOption.value === SIDEBAR_OPTIONS.FILTERS);
    const highlightsSidebarActive = computed(() => activeSidebarOption.value === SIDEBAR_OPTIONS.HIGHLIGHTS);
    const title = computed(() => {
      if (columnsSidebarActive.value) return 'Table Columns';
      if (filtersSidebarActive.value) return 'Filters';
      if (highlightsSidebarActive.value) return 'Highlights';
      return '';
    });
    const closeSidebar = () => store.dispatch('betTicker/setActiveSidebarOption', false);

    watch(selectedBetTicker, (newValue) => {
      if (newValue) return;
      closeSidebar();
    });

    return {
      title,
      activeSidebarOption,
      columnsSidebarActive,
      filtersSidebarActive,
      highlightsSidebarActive,
      closeSidebar,
    };
  },
};
</script>
<style lang="scss">
.bet-ticker-sidebar {
    width: 320px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -320px;
    background-color: #fff;
    transition: right .5s;
    z-index: $betTickerSidebarZIndex;

    &.active {
        right: 0;
    }
    .sidebar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 55px;
        padding: 16px;
        border-bottom: 1px solid $gray400;
        border-left: 1px solid $gray400;
        background-color: $white;
        font-weight: 600;
        font-size: 16px;
        .icon {
            width: 8px;
            height: 8px;
            cursor: pointer;
        }
    }
    .sidebar-content {
        height: calc(100% - 55px);
        border-left: 1px solid $gray400;
    }
}
</style>
