<template>
  <div
    :class="['id-cell', bet.multibet && 'multibet', bet.isBetPart && 'bet-part']"
  >
    <span class="id-cell__label">
      {{ bet.betId }}
    </span>
    <Icon
      class="id-cell__icon"
      :name="isActive ? 'check' : 'copy'"
      @click="copyBetId"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import { copyToClipboard } from '@/services/helpers/mappings-mapper';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    bet: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isActive = ref(false);
    const copyBetId = () => {
      copyToClipboard(props.bet.betId);
      if (!isActive.value) {
        let timeoutId = setTimeout(() => {
          isActive.value = false;
          clearTimeout(timeoutId);
          timeoutId = null;
        }, 1000);
      }
      isActive.value = true;
    };

    return {
      isActive,
      copyBetId,
    };
  },
};
</script>

<style lang="scss">
.id-cell {
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;

  &.bet-part {
    display: none;
  }

  &__label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    padding: 2px;
    stroke: #000;
    cursor: pointer;
  }
}
</style>
