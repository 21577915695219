<template>
  <div class="highlight-listing">
    <div class="highlight-listing__form-group">
      <div class="highlight-listing__form-label">
        Cell
        <Tooltip
          text="Rules order sets priority of displaying highlights in case of overlapping values"
          left
        >
          <Icon
            name="info"
          />
        </Tooltip>
      </div>
      <ul
        class="highlight-listing__list"
        @drop="onDrop('CELL')"
        @dragover.prevent
      >
        <li
          v-if="!cellHighlights.length"
          class="highlight-listing__message"
        >
          There aren't any cell highlights.
        </li>
        <HighlightListingItem
          v-for="highlight in cellHighlights"
          :key="highlight.index"
          :label="formatBetHighlight(highlight, oddsFormat)"
          :background-color="highlight.color"
          :clash="highlight.clash"
          draggable="true"
          @click="handleSelect(highlight)"
          @remove="handleRemove(highlight)"
          @dragstart="onDrag($event, highlight)"
          @dragover="onDragOver(highlight)"
        />
      </ul>
    </div>
    <div class="highlight-listing__form-group">
      <div class="highlight-listing__form-label">
        Row
        <Tooltip
          text="Rules order sets priority of displaying highlights in case of overlapping values"
          left
        >
          <Icon
            name="info"
          />
        </Tooltip>
      </div>
      <ul
        class="highlight-listing__list"
        @drop="onDrop('ROW')"
        @dragover.prevent
      >
        <li
          v-if="!rowHighlights.length"
          class="highlight-listing__message"
        >
          There aren't any row highlights.
        </li>
        <HighlightListingItem
          v-for="highlight in rowHighlights"
          :key="highlight.index"
          :label="formatBetHighlight(highlight, oddsFormat)"
          :background-color="highlight.color"
          draggable="true"
          @click="handleSelect(highlight)"
          @remove="handleRemove(highlight)"
          @dragstart="onDrag($event, highlight)"
          @dragover="onDragOver(highlight)"
        />
      </ul>
    </div>
    <div class="highlight-listing__footer">
      <Button
        variant="primary"
        icon="plus"
        @click="handleCreate"
      >
        New highlight
      </Button>
    </div>
  </div>
</template>

<script>
import {
  cloneDeep,
  set,
  filter,
  map,
} from 'lodash';
import { computed } from 'vue';
import { formatBetHighlight } from '@/services/helpers/highlights';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import Tooltip from '@/components/common/Tooltip';
import HighlightListingItem from './HighlightListingItem';

export default {
  components: {
    Icon,
    Button,
    Tooltip,
    HighlightListingItem,
  },
  props: {
    highlights: {
      type: Array,
      required: true,
    },
    oddsFormat: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:highlights': {
      type: Array,
    },
    select: {
      type: Object,
    },
    create: {},
    remove: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const rowHighlights = computed(() => filter(props.highlights, { highlightType: 'ROW' }));
    const cellHighlights = computed(() => filter(props.highlights, { highlightType: 'CELL' }));

    const reorderContext = {};
    const onDrag = (event, highlight) => {
      set(event, 'dataTransfer.dropEffect', 'none');
      set(event, 'dataTransfer.effectAllowed', 'move');
      reorderContext.selected = '';
      reorderContext.target = '';
      reorderContext.selected = highlight;
    };
    const onDragOver = (highlight) => {
      reorderContext.target = highlight;
    };
    const onDrop = (highlightType) => {
      const fromIndex = reorderContext.selected.index;
      const toIndex = reorderContext.target.index || 0;
      const highlight = props.highlights[fromIndex];

      if (highlightType !== highlight?.highlightType) return;

      let newHighlights = cloneDeep(props.highlights);
      newHighlights.splice(fromIndex, 1);
      newHighlights.splice(toIndex, 0, highlight);
      newHighlights = map(newHighlights, (currentHighlight, newIndex) => ({
        ...currentHighlight,
        index: newIndex,
      }));
      emit('update:highlights', newHighlights);
    };

    const handleSelect = (highlight) => {
      emit('select', highlight);
    };
    const handleCreate = () => {
      emit('create');
    };
    const handleRemove = (highlight) => {
      emit('remove', highlight);
    };

    return {
      rowHighlights,
      cellHighlights,
      formatBetHighlight,
      onDrag,
      onDragOver,
      onDrop,
      handleSelect,
      handleCreate,
      handleRemove,
    };
  },
};
</script>

<style lang="scss">
.highlight-listing {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  &__form {
    &-group {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 0 16px;
    }

    &-label {
      display: flex;
      align-items: center;
      gap: 4px;
      font-family: 'Rubik', sans-serif;
      font-size: 10px;
      font-weight: 600;
      line-height: 11.85px;
      color: #6D6D6D;
      text-transform: uppercase;

      .icon {
        display: inline-block;
        fill: #6D6D6D;
        width: 12px;
        height: 12px;
        max-width: 12px;
        max-height: 12px;
      }

      .tooltip {
        text-transform: none;
        font-weight: 400;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__message {
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    padding: 16px;
    height: 64px;
    border-top: 1px solid #F0F0F0;

    .button {
      min-width: 130px;
      text-align: center;
      justify-content: center;
      margin-left: auto;

      .icon {
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}
</style>
