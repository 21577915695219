<template>
  <li
    class="highlight-listing-item"
    @mouseenter="showDropdownToggle"
    @mouseleave="hideDropdownToggle"
  >
    <Icon
      class="highlight-listing-item__selector"
      name="chevron-selector"
    />
    <div
      class="highlight-listing-item__button"
      @click.stop="handleClick"
    >
      <div class="highlight-listing-item__button-label">
        <span
          class="highlight-listing-item__button-color"
          :style="{ 'background-color': backgroundColor }"
        />
        <Tooltip
          text="This highlight shares the same values as an already existing highlight rule"
          left
        >
          <Icon
            v-if="clash"
            class="highlight-listing-item__button-icon"
            name="info"
          />
        </Tooltip>
        {{ label }}
      </div>
      <div
        v-if="isDropdownToggleVisible"
        ref="dropdownElement"
        class="highlight-listing-item__dropdown"
      >
        <div
          class="highlight-listing-item__dropdown-toggle"
          @click.stop="toggleDropdownMenu"
        >
          <Icon
            name="dots-vertical"
          />
        </div>
        <div
          v-if="isDropdownMenuToggled"
          class="highlight-listing-item__dropdown-menu"
        >
          <div
            class="highlight-listing-item__dropdown-item"
            @click.stop="handleEdit"
          >
            <Icon name="pencil" />
            Edit
          </div>
          <div
            class="highlight-listing-item__dropdown-item highlight-listing-item__dropdown-item--is-danger"
            @click.stop="handleRemove"
          >
            <Icon name="trash" />
            Delete
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';

export default {
  components: {
    Icon,
    Tooltip,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
    clash: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    click: {},
    remove: {},
  },
  setup(props, { emit }) {
    const dropdownElement = ref();
    const isDropdownToggleVisible = ref(false);
    const isDropdownMenuToggled = ref(false);

    const showDropdownToggle = () => {
      isDropdownToggleVisible.value = true;
    };
    const hideDropdownToggle = () => {
      isDropdownToggleVisible.value = false;
      isDropdownMenuToggled.value = false;
    };
    const toggleDropdownMenu = () => {
      isDropdownMenuToggled.value = !isDropdownMenuToggled.value;
    };

    const handleClick = () => {
      emit('click');
    };
    const handleEdit = () => {
      handleClick();
    };
    const handleRemove = () => {
      emit('remove');
    };

    onClickOutside(dropdownElement, () => {
      isDropdownMenuToggled.value = false;
    });

    return {
      dropdownElement,
      isDropdownToggleVisible,
      isDropdownMenuToggled,
      toggleDropdownMenu,
      showDropdownToggle,
      hideDropdownToggle,
      handleClick,
      handleEdit,
      handleRemove,
    };
  },
};
</script>

<style lang="scss">
.highlight-listing-item {
  display: flex;
  align-items: center;
  gap: 4px;

  &__selector {
    cursor: move;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    padding: 12px calc(8px + 4px) 12px calc(8px + 16px + 4px);
    width: 100%;
    height: 32px;
    cursor: pointer;
    position: relative;

    &-label {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &-color {
      position: absolute;
      left: 8px;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      border: 1px solid #000;
    }

    &-icon {
      svg {
        path {
          stroke: #f00;
        }
      }
    }
  }

  &__dropdown {
    &-toggle {
      width: 24px;
      height: 24px;
      padding: 4px;
    }

    &-menu {
      position: absolute;
      top: calc(100% - 4px);
      right: 0;
      width: 150px;
      overflow-y: auto;
      z-index: 9000;
      display: flex;
      flex-direction: column;
      border-radius: var(--radius-medium, 4px);
      border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
      background: var(--neutral-bg-default-resting, #FFF);
      box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);
      // margin-top: 4px;
      padding: 4px 0;
      user-select: none;
    }

    &-item {
      display: flex;
      align-items: center;
      padding: 8px;
      gap: 8px;
      background: var(--neutral-bg-default-resting, #FFF);
      overflow: hidden;
      color: var(--neutral-text-default-resting, #191414);
      text-overflow: ellipsis;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
      width: 100%;
      flex: 1;
      cursor: pointer;

      &--is-danger {
        color: #FF2E2D;

        svg {
          path {
            fill: #FF2E2D;
          }
        }
      }

      &:hover,
      &:focus {
        background-color: #FAFAFA;
        outline: none;
      }
    }
  }
}
</style>
