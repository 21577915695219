<template>
  <div
    ref="rootElement"
    class="highlight-form-select"
  >
    <div
      class="highlight-form-select__input"
      @click="toggleMenu"
    >
      <input
        class="highlight-form-select__input-field"
        :value="inputText"
        :placeholder="placeholder"
        readonly
      >
      <Icon
        v-if="modelValue"
        class="highlight-form-select__input-button highlight-form-select__input-button--is-clear-button"
        name="x"
        @click.stop="clearModelValue"
      />
      <Icon
        v-else
        class="highlight-form-select__input-button highlight-form-select__input-button--is-chevron-button"
        name="chevron-selector"
      />
    </div>
    <ul
      v-if="isMenuToggled"
      class="highlight-form-select__menu"
    >
      <li
        v-for="option in options"
        :key="option[idKey]"
        class="highlight-form-select__menu-option"
        tabindex="0"
        @click="updateModelValue(option[idKey])"
      >
        <RadioInput
          :checked="modelValue === option[idKey]"
        />
        {{ option.label }}
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';
import RadioInput from '@/components/common/RadioInput';

export default {
  components: {
    Icon,
    RadioInput,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    idKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
  },
  setup(props, { emit }) {
    const rootElement = ref(null);
    const isMenuToggled = ref(false);

    const inputText = computed(() => {
      if (!props.modelValue) return '';
      return props.options[props.modelValue]?.[props.labelKey] || 'N/A';
    });

    const toggleMenu = () => {
      isMenuToggled.value = !isMenuToggled.value;
    };
    const updateModelValue = (newModelValue) => {
      emit('update:modelValue', newModelValue);
      isMenuToggled.value = false;
    };
    const clearModelValue = () => {
      updateModelValue('');
    };

    onClickOutside(rootElement, () => {
      isMenuToggled.value = false;
    });

    return {
      rootElement,
      isMenuToggled,
      inputText,
      toggleMenu,
      updateModelValue,
      clearModelValue,
    };
  },
};
</script>

<style lang="scss">
.highlight-form-select {
  display: flex;
  position: relative;

  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-field {
      position: relative;
      background-color: #fff;
      border: 1px solid #F0F0F0;
      padding: 8px;
      width: 100%;
      height: 32px;
      border-radius: 4px;
      cursor: pointer;

      &::placeholder {
        color: #CDCDCD;
      }

      &:focus {
        outline: 1px solid #a2bfff;
      }
    }

    &-button {
      position: absolute;
      right: 8px;

      &--is-clear-button {
        stroke: #000;
        cursor: pointer;
      }

      &--is-chevron-button {
        pointer-events: none;
      }
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    overflow-y: auto;
    z-index: 9000;
    display: flex;
    flex-direction: column;
    border-radius: var(--radius-medium, 4px);
    border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
    background: var(--neutral-bg-default-resting, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);
    margin-top: 4px;
    padding: 4px 0;
    user-select: none;

    &-option {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      height: 32px;
      padding: 8px;

      &:hover,
      &:focus {
        background-color: #FAFAFA;
        outline: none;
      }

      .radio-input {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
