<template>
  <div
    :class="[
      'bet-ticker-filter-uuid-text-input',
      { 'bet-ticker-filter-uuid-text-input--is-error': error },
    ]"
  >
    <input
      class="bet-ticker-filter-uuid-text-input__input"
      type="text"
      placeholder="N/A"
      :value="parameter"
      @blur="setParameter($event.target.value)"
    >
    <div class="bet-ticker-filter-uuid-text-input__icon">
      <Icon
        class="remove-item"
        name="x"
        @click.stop="removeFilter"
      />
    </div>
  </div>
</template>

<script>
import { trim } from 'lodash';
import { computed } from 'vue';
import { conditionTypes } from '@/services/helpers/bet-ticker-filters';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:filter': {
      type: Object,
    },
    removeFilter: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const parameter = computed(() => props.filter.parameter || '');
    const setParameter = (newParameter) => {
      if (!trim(newParameter)) {
        emit('update:filter', {
          ...props.filter,
          conditionType: '',
          parameter: '',
        });
        return;
      }

      emit('update:filter', {
        ...props.filter,
        conditionType: conditionTypes.EQUAL,
        parameter: newParameter,
      });
    };
    const removeFilter = () => {
      emit('removeFilter', props.filter);
    };

    return {
      parameter,
      setParameter,
      removeFilter,
    };
  },
};
</script>

<style lang="scss">
.bet-ticker-filter-uuid-text-input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  width: 100%;
  height: 32px;

  &:focus-within {
    outline: 1px solid #a2bfff;
  }

  &--is-error,
  &--is-error:focus-within {
    outline: 1px solid #f00;
  }

  &__input {
    width: 100%;
    height: 100%;
    padding: 12px 32px 12px 12px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #191414;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    position: absolute;
    right: 12px;

    .icon {
      cursor: pointer;

      svg {
        stroke: #191414;
      }
    }
  }
}
</style>
