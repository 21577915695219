<template>
  <div
    ref="rootElement"
    class="bet-ticker-filter-condition-select"
  >
    <div
      :class="[
        'bet-ticker-filter-condition-select__button',
        { 'bet-ticker-filter-condition-select__button--is-active': isMenuToggled }
      ]"
      @click="toggleMenu"
    >
      <div class="bet-ticker-filter-condition-select__button-label">
        {{ label }}
      </div>
      <div class="bet-ticker-filter-condition-select__button-icons">
        <Icon
          name="chevron-selector"
        />
        <Icon
          class="remove-item"
          name="x"
          @click.stop="removeFilter"
        />
      </div>
    </div>
    <ul
      v-show="isMenuToggled"
      class="bet-ticker-filter-condition-select__menu"
    >
      <li
        v-for="option in conditionTypeOptions"
        :key="option.id"
        class="bet-ticker-filter-condition-select__menu-option"
        tabindex="0"
        @click="setCondition(option)"
      >
        <RadioInput
          :checked="conditionType?.id === option.id"
        />
        {{ option.label }}
      </li>
      <li class="bet-ticker-filter-condition-select__menu-option">
        <div class="bet-ticker-filter-condition-select__input">
          <input
            class="bet-ticker-filter-condition-select__input-field"
            :value="parameter"
            @blur="setParameter($event.target.value)"
          >
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { cloneDeep, isNil } from 'lodash';
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { getConditionTypeOptions } from '@/services/helpers/bet-ticker-filters';
import Icon from '@/components/common/Icon';
import RadioInput from '@/components/common/RadioInput';

export default {
  components: {
    Icon,
    RadioInput,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  emits: {
    'update:filter': {
      type: Object,
    },
    removeFilter: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const rootElement = ref(null);
    const isMenuToggled = ref(false);
    const filter = ref(cloneDeep(props.filter));

    const type = computed(() => filter.value.type);
    const conditionType = computed(() => filter.value.conditionType || null);
    const parameter = computed(() => filter.value.parameter || 0);
    const label = computed(() => {
      const { shortLabel = '' } = conditionType.value || {};
      if (!shortLabel || isNil(parameter.value)) return 'N/A';
      return `${shortLabel} "${parameter.value}"`;
    });

    const toggleMenu = () => {
      if (isMenuToggled.value) {
        emit('update:filter', cloneDeep({
          ...props.filter,
          ...filter.value,
        }));
      }
      isMenuToggled.value = !isMenuToggled.value;
    };
    const setCondition = (newCondition) => {
      filter.value = {
        ...filter.value,
        conditionType: newCondition,
      };
    };
    const setParameter = (newParameter) => {
      filter.value = {
        ...filter.value,
        parameter: +newParameter,
      };
    };
    const removeFilter = () => {
      emit('removeFilter', props.filter);
    };

    onClickOutside(rootElement, () => {
      if (isMenuToggled.value) {
        emit('update:filter', cloneDeep({
          ...props.filter,
          ...filter.value,
        }));
      }
      isMenuToggled.value = false;
    });

    return {
      conditionTypeOptions: getConditionTypeOptions(),
      rootElement,
      isMenuToggled,
      type,
      conditionType,
      parameter,
      label,
      toggleMenu,
      setCondition,
      setParameter,
      removeFilter,
    };
  },
};
</script>

<style lang="scss">
.bet-ticker-filter-condition-select {
  width: 100%;
  position: relative;

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    height: 32px;
    cursor: pointer;
    position: relative;

    &--is-active {
      outline: 1px solid #a2bfff;
    }

    &-label {
      display: flex;
      align-items: center;
    }

    &-icons {
      display: flex;

      .icon {
        width: 16px;
        height: 16px;
        margin-left: 4px;
        cursor: pointer;

        &.remove-item {
          svg {
            stroke: #191414;
          }
        }
      }
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    overflow-y: auto;
    z-index: 9000;
    display: flex;
    flex-direction: column;
    border-radius: var(--radius-medium, 4px);
    border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
    background: var(--neutral-bg-default-resting, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);
    margin-top: 4px;
    padding: 4px 0;
    user-select: none;

    &-option {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      height: 32px;
      padding: 8px;

      &:hover,
      &:focus {
        background-color: #FAFAFA;
        outline: none;
      }

      .radio-input {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-field {
      position: relative;
      background-color: #fff;
      border: 1px solid #F0F0F0;
      padding: 8px;
      width: 100%;
      height: 32px;
      border-radius: 4px;
      cursor: pointer;

      &::placeholder {
        color: #CDCDCD;
      }

      &:focus {
        outline: 1px solid #a2bfff;
      }
    }
  }
}
</style>
