<template>
  <div class="highlight-form-preview">
    <div class="highlight-form-preview__label">
      Preview
    </div>
    <div class="highlight-form-preview__grid">
      <div class="highlight-form-preview__cell">
        <span class="highlight-form-preview__blob" />
      </div>
      <div class="highlight-form-preview__cell">
        <span class="highlight-form-preview__blob" />
      </div>
      <div class="highlight-form-preview__cell">
        <span class="highlight-form-preview__blob" />
      </div>
      <div
        v-if="type === 'ROW'"
        class="highlight-form-preview__cell"
        :style="previewCellStyle"
      >
        <span
          class="highlight-form-preview__blob"
          :style="previewBlobStyle"
        />
      </div>
      <div
        v-else
        class="highlight-form-preview__cell"
      >
        <span class="highlight-form-preview__blob" />
      </div>
      <div
        class="highlight-form-preview__cell"
        :style="previewCellStyle"
      >
        Moneyline
      </div>
      <div
        v-if="type === 'ROW'"
        class="highlight-form-preview__cell"
        :style="previewCellStyle"
      >
        <span
          class="highlight-form-preview__blob"
          :style="previewBlobStyle"
        />
      </div>
      <div
        v-else
        class="highlight-form-preview__cell"
      >
        <span class="highlight-form-preview__blob" />
      </div>
      <div class="highlight-form-preview__cell">
        <span class="highlight-form-preview__blob" />
      </div>
      <div class="highlight-form-preview__cell">
        <span class="highlight-form-preview__blob" />
      </div>
      <div class="highlight-form-preview__cell">
        <span class="highlight-form-preview__blob" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const previewCellStyle = computed(() => ({
      'background-color': props.backgroundColor,
      color: props.color === 'LIGHT' ? '#FFF' : '#000',
    }));
    const previewBlobStyle = computed(() => ({
      'background-color': props.color === 'LIGHT' ? '#FFF' : '#000',
    }));

    return {
      previewCellStyle,
      previewBlobStyle,
    };
  },
};
</script>

<style lang="scss">
.highlight-form-preview {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;

  &__label {
    font-family: 'Rubik', sans-serif;
    font-size: 10px;
    font-weight: 600;
    line-height: 11.85px;
    color: #6D6D6D;
    text-transform: uppercase;
  }

  &__grid {
    display: grid;
    grid-template-rows: repeat(3, 44px);
    grid-template-columns: repeat(3, 1fr);
  }

  &__cell {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
  }

  &__blob {
    display: inline-block;
    background-color: #DDDDDD;
    width: 100%;
    height: 12px;
    border-radius: 100px;
  }
}
</style>
