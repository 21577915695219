<template>
  <div class="highlights-sidebar">
    <div
      v-if="!highlights.length && !selectedHighlight"
      class="highlights-sidebar__empty"
    >
      Create rules to highlight only important data!
      <Button
        variant="primary"
        @click="setNewHighlight"
      >
        New highlight
      </Button>
    </div>
    <HighlightListing
      v-else-if="!selectedHighlight"
      :highlights="highlights"
      :odds-format="oddsFormat"
      @update:highlights="updateHighlights"
      @select="setSelectedHighlight"
      @create="setNewHighlight"
      @remove="removeHighlight"
    />
    <HighlightForm
      v-else
      v-model:highlight="selectedHighlight"
      :odds-format="oddsFormat"
      @submit="submitSelectedHighlight"
      @cancel="resetSelectedHighlight"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { cloneDeep } from 'lodash';
import Button from '@/components/common/Button';
import HighlightListing from './HighlightListing';
import HighlightForm from './HighlightForm';

export default {
  components: {
    Button,
    HighlightListing,
    HighlightForm,
  },
  setup() {
    const store = useStore();

    const selectedHighlight = ref(null);
    const originalSelectedHighlight = ref(null);

    const highlights = computed(() => store.getters['betTicker/betTickerHighlights']);
    const oddsFormat = computed(() => store.getters.selectedOddFormat?.name);

    const updateHighlights = (newHighlights) => {
      store.dispatch('betTicker/setBetTickerHighlights', newHighlights);
    };
    const setNewHighlight = () => {
      selectedHighlight.value = {
        type: '',
        parameter: '',
        conditionType: '',
        color: '',
        highlightType: 'ROW',
        textColor: 'DARK',
      };
      originalSelectedHighlight.value = null;
    };
    const setSelectedHighlight = (newHighlight) => {
      selectedHighlight.value = cloneDeep(newHighlight);
      originalSelectedHighlight.value = cloneDeep(newHighlight);
    };
    const submitSelectedHighlight = () => {
      if (originalSelectedHighlight.value) {
        store.dispatch('betTicker/replaceBetTickerHighlight', {
          index: selectedHighlight.value.index,
          highlight: selectedHighlight.value,
        });
      } else {
        store.dispatch('betTicker/addBetTickerHighlight', {
          highlight: selectedHighlight.value,
        });
      }
      selectedHighlight.value = null;
      originalSelectedHighlight.value = null;
    };
    const resetSelectedHighlight = () => {
      setSelectedHighlight(null);
    };
    const removeHighlight = (highlight) => {
      store.dispatch('betTicker/removeBetTickerHighlight', highlight.index);
    };

    watch(
      () => store.getters['betTicker/selectedBetTicker']?.betTickerId,
      () => {
        selectedHighlight.value = null;
        originalSelectedHighlight.value = null;
      },
    );

    return {
      selectedHighlight,
      highlights,
      oddsFormat,
      updateHighlights,
      setNewHighlight,
      setSelectedHighlight,
      submitSelectedHighlight,
      resetSelectedHighlight,
      removeHighlight,
    };
  },
};
</script>

<style lang="scss">
.highlights-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px 0 0 0;

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 32px 16px 0;
    gap: 32px;
    text-align: center;
    max-width: 240px;
    margin: 0 auto;
  }
}
</style>
